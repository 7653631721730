var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "제출서류",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("LUMINO 816 청약신청 제출서류")];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-01.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 1. 서류 체크리스트 [일반공급] ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-02.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 2. 서류 체크리스트 [특별공급] ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-03.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 3. 공공지원민간임대주택 등 공급신청서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-04.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 4. 서약서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-05.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 5. 월평균 소득현황 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-06.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 6. 무소득 확인서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-07.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 7. 개인정보 활용 동의서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-08.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 8. 자산 보유 사실 확인서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-09.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 9. 금융정보등(금융,신용,보험정보)제공 동의서 ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "document",
    attrs: {
      "src": "/images/sub/subscription/document/document-10.jpg"
    }
  }, [_c('div', {
    staticClass: "document__caption"
  }, [_vm._v(" 10. 비사업자 확인각서 ")])])], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('btn-primary', {
    attrs: {
      "x-large": "",
      "href": "/assets/document/documents.zip",
      "target": "_blank"
    }
  }, [_vm._v("제출서류 다운로드"), _c('v-img', {
    staticClass: "ml-16px ml-lg-24px",
    attrs: {
      "src": "/images/icon/icon-download-white.png"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }