var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "모집공고문",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("모집공고")];
      },
      proxy: true
    }])
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/business/overview/overview.jpg"
    }
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('btn-primary', {
    attrs: {
      "x-large": "",
      "href": "",
      "target": "_blank"
    }
  }, [_vm._v("모집공고 다운로드"), _c('v-img', {
    staticClass: "ml-16px ml-lg-24px",
    attrs: {
      "src": "/images/icon/icon-download-white.png"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }