<template>
    <v-sheet class="unit-content">
        <div class="v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable">
            <div class="v-data-table__wrapper">
                <table>
                    <thead>
                        <tr>
                            <th-primary>TYPE</th-primary>
                            <th-primary>전용면적</th-primary>
                            <th-primary>공용면적</th-primary>
                            <th-primary>공급면적</th-primary>
                            <th-primary>주차장 외 공용</th-primary>
                            <th-primary>계약면적</th-primary>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- S: A TYPE -->
                        <tr v-if="$route.hash == '#a' || $route.hash == ''">
                            <th-secondary>A TYPE</th-secondary>
                            <td-primary>17.65㎡</td-primary>
                            <td-primary>12.77㎡</td-primary>
                            <td-primary>30.42㎡</td-primary>
                            <td-primary>4.61㎡</td-primary>
                            <td-primary>35.03㎡</td-primary>
                        </tr>
                        <!-- E: A TYPE -->

                        <!-- S: B TYPE -->
                        <tr v-if="$route.hash == '#b'">
                            <th-secondary>B TYPE</th-secondary>
                            <td-primary>35.84㎡</td-primary>
                            <td-primary>24.92㎡</td-primary>
                            <td-primary>60.76㎡</td-primary>
                            <td-primary>9.36㎡</td-primary>
                            <td-primary>70.12㎡</td-primary>
                        </tr>
                        <!-- E: B TYPE -->

                        <!-- S: C1 TYPE -->
                        <tr v-if="$route.hash == '#c1'">
                            <th-secondary>C TYPE</th-secondary>
                            <td-primary>35.14㎡</td-primary>
                            <td-primary>24.22㎡</td-primary>
                            <td-primary>59.36㎡</td-primary>
                            <td-primary>9.18㎡</td-primary>
                            <td-primary>68.54㎡</td-primary>
                        </tr>
                        <!-- E: C1 TYPE --> 

                        <!-- S: C2TYPE -->
                        <tr v-if="$route.hash == '#c2'">
                            <th-secondary>C TYPE</th-secondary>
                            <td-primary>35.14㎡</td-primary>
                            <td-primary>24.22㎡</td-primary>
                            <td-primary>59.36㎡</td-primary>
                            <td-primary>9.18㎡</td-primary>
                            <td-primary>68.54㎡</td-primary>
                        </tr>
                        <!-- E: C2 TYPE --> 

                        <!-- S: D TYPE -->
                        <tr v-if="$route.hash == '#d'">
                            <th-secondary>D TYPE</th-secondary>
                            <td-primary>12.02㎡</td-primary>
                            <td-primary>12.31㎡</td-primary>
                            <td-primary>29.33㎡</td-primary>
                            <td-primary>4.45㎡</td-primary>
                            <td-primary>33.77㎡</td-primary>
                        </tr>
                        <!-- E: D TYPE -->

                        <!-- S: E TYPE -->
                        <tr v-if="$route.hash == '#e'">
                            <th-secondary>E TYPE</th-secondary>
                            <td-primary>17.54㎡</td-primary>
                            <td-primary>12.63㎡</td-primary>
                            <td-primary>30.17㎡</td-primary>
                            <td-primary>4.58㎡</td-primary>
                            <td-primary>34.75㎡</td-primary>
                        </tr>
                        <!-- E: E TYPE -->

                        <!-- S: F1 TYPE -->
                        <tr v-if="$route.hash == '#f1'">
                            <th-secondary>F TYPE</th-secondary>
                            <td-primary>34.72㎡</td-primary>
                            <td-primary>24.04㎡</td-primary>
                            <td-primary>58.76㎡</td-primary>
                            <td-primary>9.07㎡</td-primary>
                            <td-primary>67.83㎡</td-primary>
                        </tr>
                        <!-- E: F1 TYPE -->

                        <!-- S: F2 TYPE -->
                        <tr v-if="$route.hash == '#f2'">
                            <th-secondary>F TYPE</th-secondary>
                            <td-primary>34.72㎡</td-primary>
                            <td-primary>24.04㎡</td-primary>
                            <td-primary>58.76㎡</td-primary>
                            <td-primary>9.07㎡</td-primary>
                            <td-primary>67.83㎡</td-primary>
                        </tr>
                        <!-- E: F2 TYPE -->

                        <!-- S: G TYPE -->
                        <tr v-if="$route.hash == '#g'">
                            <th-secondary>G TYPE</th-secondary>
                            <td-primary>36.39㎡</td-primary>
                            <td-primary>25.60㎡</td-primary>
                            <td-primary>61.99㎡</td-primary>
                            <td-primary>9.51㎡</td-primary>
                            <td-primary>71.49㎡</td-primary>
                        </tr>
                        <!-- E: G TYPE -->

                        <!-- S: H TYPE -->
                        <tr v-if="$route.hash == '#h'">
                            <th-secondary>H TYPE</th-secondary>
                            <td-primary>39.03㎡</td-primary>
                            <td-primary>26.98㎡</td-primary>
                            <td-primary>66.01㎡</td-primary>
                            <td-primary>10.19㎡</td-primary>
                            <td-primary>76.20㎡</td-primary>
                        </tr>
                        <!-- E: H TYPE -->
                    </tbody>
                </table>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {
        type: { type: String, default: "" },
        area2: { type: String, default: "" },
        area3: { type: String, default: "" },
    },
    components: {
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
