<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="단지안내" tabActive="호수 배치도" bg="/images/sub/complex/visual.jpg">
                <template #title>단지안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>호수 배치도</template>

            <div class="page-section__content">
                <sheet-tertiary>
                    <div>
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <chip-quarternary class="w-md-140px justify-center white--text">청년형</chip-quarternary>
                            </v-col>
                            <v-col cols="12" md="">
                                <v-row class="row--xs">
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="secondary lighten-4">
                                            <span class="grey--text">A TYPE(17.65) 100세대 중 75세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="primary lighten-4">
                                            <span class="grey--text">D TYPE(17.02) 80세대 중 63세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="tertiary lighten-4">
                                            <span class="grey--text">E TYPE(17.54) 19세대 중 15세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="pt-18px pt-md-24px">
                        <v-row class="row--xs">
                            <v-col cols="auto">
                                <chip-quarternary class="w-md-140px justify-center white--text">신혼부부형</chip-quarternary>
                            </v-col>
                            <v-col cols="12" md="">
                                <v-row class="row--xs">
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#FFCA99">
                                            <span class="grey--text">B TYPE(35.84) 58세대 중 44세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#FDFF9E">
                                            <span class="grey--text">C2 TYPE(35.14) 16세대 중 13세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#FFECFA">
                                            <span class="grey--text">C1 TYPE(35.14) 16세대 중 10세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#BBEAC0">
                                            <span class="grey--text">F1 TYPE(34.72) 3세대 중 2세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#72DDFF">
                                            <span class="grey--text">F2 TYPE(34.72) 3세대 중 2세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#F0A7F1">
                                            <span class="grey--text">G TYPE(36.39) 2세대 중 2세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                    <v-col cols="12" lg="6" xl="4">
                                        <chip-quarternary color="#FFDA57">
                                            <span class="grey--text">H TYPE(39.03) 2세대 중 2세대</span>
                                        </chip-quarternary>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </sheet-tertiary>
            </div>

            <div class="page-section__content">
                <div class="v-data-table v-data-table--line v-data-table--density-compact">
                    <div class="v-data-table__wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th-primary>층별</th-primary>
                                    <th-primary>1호</th-primary>
                                    <th-primary>2호</th-primary>
                                    <th-primary>3호</th-primary>
                                    <th-primary>4호</th-primary>
                                    <th-primary>5호</th-primary>
                                    <th-primary>6호</th-primary>
                                    <th-primary>7호</th-primary>
                                    <th-primary>8호</th-primary>
                                    <th-primary>9호</th-primary>
                                    <th-primary>10호</th-primary>
                                    <th-primary>11호</th-primary>
                                    <th-primary>12호</th-primary>
                                    <th-primary>13호</th-primary>
                                    <th-primary>14호</th-primary>
                                    <th-primary>15호</th-primary>
                                    <th-primary>16호</th-primary>
                                    <th-primary>17호</th-primary>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th-secondary>22층</th-secondary>
                                    <td-primary class="bg-a">2201</td-primary>
                                    <td-primary class="bg-a">2202</td-primary>
                                    <td-primary class="bg-a">2203</td-primary>
                                    <td-primary class="bg-a">2204</td-primary>
                                    <td-primary class="bg-a">2205</td-primary>
                                    <td-primary class="bg-d">2206</td-primary>
                                    <td-primary class="bg-d">2207</td-primary>
                                    <td-primary class="bg-d">2208</td-primary>
                                    <td-primary class="bg-d">2209</td-primary>
                                    <td-primary class="bg-b">2210</td-primary>
                                    <td-primary class="bg-c2">2211</td-primary>
                                    <td-primary class="bg-c1">2212</td-primary>
                                    <td-primary class="bg-b">2213</td-primary>
                                    <td-primary class="bg-b">2214</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>21층</th-secondary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">2104</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">2109</td-primary>
                                    <td-primary class="bg-d">2110</td-primary>
                                    <td-primary class="bg-b">2111</td-primary>
                                    <td-primary class="bg-c2">2112</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">2015</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>20층</th-secondary>
                                    <td-primary class="bg-e">2001</td-primary>
                                    <td-primary class="bg-a">2002</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">2004</td-primary>
                                    <td-primary class="bg-a">2005</td-primary>
                                    <td-primary class="bg-a">2006</td-primary>
                                    <td-primary class="bg-d">2007</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">2009</td-primary>
                                    <td-primary class="bg-d">2010</td-primary>
                                    <td-primary class="bg-b">2011</td-primary>
                                    <td-primary class="bg-c2">2012</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">2014</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>19층</th-secondary>
                                    <td-primary class="bg-e">1901</td-primary>
                                    <td-primary class="bg-a">1902</td-primary>
                                    <td-primary class="bg-a">1903</td-primary>
                                    <td-primary class="bg-a">1904</td-primary>
                                    <td-primary class="bg-a">1905</td-primary>
                                    <td-primary class="bg-a">1906</td-primary>
                                    <td-primary class="bg-d">1907</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1909</td-primary>
                                    <td-primary class="bg-d">1910</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-c1">1913</td-primary>
                                    <td-primary class="bg-b">1914</td-primary>
                                    <td-primary class="bg-b">1915</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>18층</th-secondary>
                                    <td-primary class="bg-e">1801</td-primary>
                                    <td-primary class="bg-a">1802</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1806</td-primary>
                                    <td-primary class="bg-d">1807</td-primary>
                                    <td-primary class="bg-d">1808</td-primary>
                                    <td-primary class="bg-d">1809</td-primary>
                                    <td-primary class="bg-d">1810</td-primary>
                                    <td-primary class="bg-b">1811</td-primary>
                                    <td-primary class="bg-c2">1812</td-primary>
                                    <td-primary class="bg-c1">1813</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>17층</th-secondary>
                                    <td-primary class="bg-e">1701</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1703</td-primary>
                                    <td-primary class="bg-a">1704</td-primary>
                                    <td-primary class="bg-a">1705</td-primary>
                                    <td-primary class="bg-a">1706</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1708</td-primary>
                                    <td-primary class="bg-d">1709</td-primary>
                                    <td-primary class="bg-d">1710</td-primary>
                                    <td-primary class="bg-b">1711</td-primary>
                                    <td-primary class="bg-c2">1712</td-primary>
                                    <td-primary class="bg-c1">1713</td-primary>
                                    <td-primary class="bg-b">1714</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>16층</th-secondary>
                                    <td-primary class="bg-e">1601</td-primary>
                                    <td-primary class="bg-a">1602</td-primary>
                                    <td-primary class="bg-a">1603</td-primary>
                                    <td-primary class="bg-a">1604</td-primary>
                                    <td-primary class="bg-a">1605</td-primary>
                                    <td-primary class="bg-a">1606</td-primary>
                                    <td-primary class="bg-d">1607</td-primary>
                                    <td-primary class="bg-d">1608</td-primary>
                                    <td-primary class="bg-d">1609</td-primary>
                                    <td-primary class="bg-d">1610</td-primary>
                                    <td-primary class="bg-b">1611</td-primary>
                                    <td-primary class="bg-c2">1612</td-primary>
                                    <td-primary class="bg-c1">1613</td-primary>
                                    <td-primary class="bg-b">1614</td-primary>
                                    <td-primary class="bg-b">1615</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>15층</th-secondary>
                                    <td-primary class="bg-e">1501</td-primary>
                                    <td-primary class="bg-a">1502</td-primary>
                                    <td-primary class="bg-a">1503</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1505</td-primary>
                                    <td-primary class="bg-a">1506</td-primary>
                                    <td-primary class="bg-d">1507</td-primary>
                                    <td-primary class="bg-d">1508</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1510</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">1514</td-primary>
                                    <td-primary class="bg-b">1515</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>14층</th-secondary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1402</td-primary>
                                    <td-primary class="bg-a">1403</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1405</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1408</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1410</td-primary>
                                    <td-primary class="bg-b">1411</td-primary>
                                    <td-primary class="bg-c2">1412</td-primary>
                                    <td-primary class="bg-c1">1413</td-primary>
                                    <td-primary class="bg-b">1414</td-primary>
                                    <td-primary class="bg-b">1415</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>13층</th-secondary>
                                    <td-primary class="bg-e">1301</td-primary>
                                    <td-primary class="bg-a">1302</td-primary>
                                    <td-primary class="bg-a">1303</td-primary>
                                    <td-primary class="bg-a">1304</td-primary>
                                    <td-primary class="bg-a">1305</td-primary>
                                    <td-primary class="bg-a">1306</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1308</td-primary>
                                    <td-primary class="bg-d">1309</td-primary>
                                    <td-primary class="bg-d">1310</td-primary>
                                    <td-primary class="bg-b">1311</td-primary>
                                    <td-primary class="bg-c2">1312</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">1314</td-primary>
                                    <td-primary class="bg-b">1315</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>12층</th-secondary>
                                    <td-primary class="bg-e">1201</td-primary>
                                    <td-primary class="bg-a">1202</td-primary>
                                    <td-primary class="bg-a">1203</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1206</td-primary>
                                    <td-primary class="bg-d">1207</td-primary>
                                    <td-primary class="bg-d">1208</td-primary>
                                    <td-primary class="bg-d">1209</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">1211</td-primary>
                                    <td-primary class="bg-c2">1212</td-primary>
                                    <td-primary class="bg-c1">1213</td-primary>
                                    <td-primary class="bg-b">1214</td-primary>
                                    <td-primary class="bg-b">1215</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>11층</th-secondary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1102</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1104</td-primary>
                                    <td-primary class="bg-a">1105</td-primary>
                                    <td-primary class="bg-a">1106</td-primary>
                                    <td-primary class="bg-d">1107</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">1109</td-primary>
                                    <td-primary class="bg-d">1110</td-primary>
                                    <td-primary class="bg-b">1111</td-primary>
                                    <td-primary class="bg-c2">1112</td-primary>
                                    <td-primary class="bg-c1">1113</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">1115</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>10층</th-secondary>
                                    <td-primary class="bg-e">1001</td-primary>
                                    <td-primary class="bg-a">1002</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">1004</td-primary>
                                    <td-primary class="bg-a">1005</td-primary>
                                    <td-primary class="bg-a">1006</td-primary>
                                    <td-primary class="bg-d">1007</td-primary>
                                    <td-primary class="bg-d">1008</td-primary>
                                    <td-primary class="bg-d">1009</td-primary>
                                    <td-primary class="bg-d">1010</td-primary>
                                    <td-primary class="bg-b">1011</td-primary>
                                    <td-primary class="bg-c2">1012</td-primary>
                                    <td-primary class="bg-c1">1013</td-primary>
                                    <td-primary class="bg-b">1014</td-primary>
                                    <td-primary class="bg-b">1015</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>9층</th-secondary>
                                    <td-primary class="bg-e">901</td-primary>
                                    <td-primary class="bg-a">902</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">905</td-primary>
                                    <td-primary class="bg-a">906</td-primary>
                                    <td-primary class="bg-d">907</td-primary>
                                    <td-primary class="bg-d">908</td-primary>
                                    <td-primary class="bg-d">909</td-primary>
                                    <td-primary class="bg-d">910</td-primary>
                                    <td-primary class="bg-b">911</td-primary>
                                    <td-primary class="bg-c2">912</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">915</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>8층</th-secondary>
                                    <td-primary class="bg-e">801</td-primary>
                                    <td-primary class="bg-a">802</td-primary>
                                    <td-primary class="bg-a">803</td-primary>
                                    <td-primary class="bg-a">804</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">806</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">810</td-primary>
                                    <td-primary class="bg-b">811</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-c1">813</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">815</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>7층</th-secondary>
                                    <td-primary class="bg-e">701</td-primary>
                                    <td-primary class="bg-a">702</td-primary>
                                    <td-primary class="bg-a">703</td-primary>
                                    <td-primary class="bg-a">704</td-primary>
                                    <td-primary class="bg-a">705</td-primary>
                                    <td-primary class="bg-a">706</td-primary>
                                    <td-primary class="bg-d">707</td-primary>
                                    <td-primary class="bg-d">708</td-primary>
                                    <td-primary class="bg-d">709</td-primary>
                                    <td-primary class="bg-d">710</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-c2">711</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">715</td-primary>
                                    <td-primary class="bg-b">716</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>6층</th-secondary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">602</td-primary>
                                    <td-primary class="bg-a">603</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">605</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">607</td-primary>
                                    <td-primary class="bg-d">608</td-primary>
                                    <td-primary class="bg-d">609</td-primary>
                                    <td-primary class="bg-d">610</td-primary>
                                    <td-primary class="bg-b">611</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-f1">613</td-primary>
                                    <td-primary class="bg-b">614</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>5층</th-secondary>
                                    <td-primary class="bg-e">501</td-primary>
                                    <td-primary class="bg-a">502</td-primary>
                                    <td-primary class="bg-a">503</td-primary>
                                    <td-primary class="bg-a">504</td-primary>
                                    <td-primary class="bg-a">505</td-primary>
                                    <td-primary class="bg-a">506</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">508</td-primary>
                                    <td-primary class="bg-d">509</td-primary>
                                    <td-primary class="bg-d">510</td-primary>
                                    <td-primary class="bg-b">511</td-primary>
                                    <td-primary class="bg-f2">512</td-primary>
                                    <td-primary class="bg-f1">513</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">515</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                                <tr>
                                    <th-secondary>4층</th-secondary>
                                    <td-primary class="bg-e">401</td-primary>
                                    <td-primary class="bg-a">402</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">404</td-primary>
                                    <td-primary class="bg-a">405</td-primary>
                                    <td-primary class="bg-a">406</td-primary>
                                    <td-primary class="bg-g">407</td-primary>
                                    <td-primary class="bg-h">408</td-primary>
                                    <td-primary class="bg-d">409</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">411</td-primary>
                                    <td-primary class="bg-d">412</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-f2">414</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-b">416</td-primary>
                                    <td-primary class="bg-b">417</td-primary>
                                </tr>
                                <tr>
                                    <th-secondary>3층</th-secondary>
                                    <td-primary class="bg-e">301</td-primary>
                                    <td-primary class="bg-a">302</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-a">306</td-primary>
                                    <td-primary class="bg-g">307</td-primary>
                                    <td-primary class="bg-h">308</td-primary>
                                    <td-primary class="bg-d">309</td-primary>
                                    <td-primary>-</td-primary>
                                    <td-primary class="bg-d">311</td-primary>
                                    <td-primary class="bg-d">312</td-primary>
                                    <td-primary class="bg-b">313</td-primary>
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                    <td-primary class="empty" />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import SheetTertiary from "@/components/publish/parents/sheets/sheet-tertiary.vue";
import ChipSecondary from "@/components/publish/parents/chips/chip-secondary.vue";
import ChipQuarternary from "@/components/publish/parents/chips/chip-quarternary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        SheetTertiary,
        ChipSecondary,
        ChipQuarternary,
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-data-table {
    td {
        background-color: #b0b0b0 !important;
    }
    .bg-a {
        background-color: var(--v-secondary-lighten4) !important;
    }
    .bg-b {
        background-color: #ffca99 !important;
    }
    .bg-c1 {
        background-color: #ffecfa !important;
    }
    .bg-c2 {
        background-color: #fdff9e !important;
    }
    .bg-d {
        background-color: var(--v-primary-lighten4) !important;
    }
    .bg-e {
        background-color: var(--v-tertiary-lighten4) !important;
    }
    .bg-f1 {
        background-color: #bbeac0 !important;
    }
    .bg-f2 {
        background-color: #72ddff !important;
    }
    .bg-g {
        background-color: #f0a7f1 !important;
    }
    .bg-h {
        background-color: #ffda57 !important;
    }
    .empty {
        background-color: #fff !important;
    }
}
</style>
