var render = function render(){
  var _vm$application, _vm$application$creat, _vm$application2, _vm$QUALIFICATION_TYP, _vm$application3, _vm$application4, _vm$SUPPLY_CATEGORY$_, _vm$application5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-secondary', {
          attrs: {
            "itemTitle": "청약신청",
            "bg": "/images/form/application/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약신청")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("루미노816 청약신청 조회")];
      },
      proxy: true
    }])
  }, [_c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep"
  }, [_vm._v("접수일")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_vm._v(_vm._s((_vm$application = _vm.application) === null || _vm$application === void 0 ? void 0 : (_vm$application$creat = _vm$application.createdAt) === null || _vm$application$creat === void 0 ? void 0 : _vm$application$creat.toDateTime()))])])], 1), _c('v-divider')], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "border-r",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep"
  }, [_vm._v("이름")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_vm._v(_vm._s((_vm$application2 = _vm.application) === null || _vm$application2 === void 0 ? void 0 : _vm$application2.name))])])], 1), _c('v-divider')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep"
  }, [_vm._v("신청자격")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_vm._v(_vm._s((_vm$QUALIFICATION_TYP = _vm.QUALIFICATION_TYPE[(_vm$application3 = _vm.application) === null || _vm$application3 === void 0 ? void 0 : _vm$application3.qualification_type]) === null || _vm$QUALIFICATION_TYP === void 0 ? void 0 : _vm$QUALIFICATION_TYP.text))])])], 1), _c('v-divider')], 1)], 1), _c('v-col', {
    staticClass: "border-r",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep"
  }, [_vm._v("생년월일")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_vm._v(_vm._s(_vm.birthFormat((_vm$application4 = _vm.application) === null || _vm$application4 === void 0 ? void 0 : _vm$application4.birth)))])])], 1), _c('v-divider')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('v-row', {
    staticClass: "txt txt--sm",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep"
  }, [_vm._v("공급유형")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "pt-4px pb-16px px-10px px-md-20px py-md-16px"
  }, [_vm._v(_vm._s((_vm$SUPPLY_CATEGORY$_ = _vm.SUPPLY_CATEGORY[(_vm$application5 = _vm.application) === null || _vm$application5 === void 0 ? void 0 : _vm$application5.supply_category]) === null || _vm$SUPPLY_CATEGORY$_ === void 0 ? void 0 : _vm$SUPPLY_CATEGORY$_.text))])])], 1), _c('v-divider')], 1)], 1)], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed white--text",
    attrs: {
      "x-large": "",
      "tile": "",
      "color": "black"
    },
    on: {
      "click": _vm.go
    }
  }, [_vm._v("신청서 수정")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }