var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "임대조건",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("임대조건")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--xs"
  }, [_c('tit-tertiary', [_vm._v("특별공급")])], 1), _c('div', {
    staticClass: "pb-18px pb-md-24px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-quarternary', [_vm._v("총 46세대")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip-group', [_c('chip-tertiary', {
    attrs: {
      "color": "quarternary"
    }
  }, [_vm._v("청년형 | 31세대")]), _c('chip-tertiary', {
    attrs: {
      "color": "tertiary"
    }
  }, [_vm._v("신혼부부형 | 15세대")])], 1)], 1)], 1)], 1), _c('txt-primary', {
    staticClass: "text-right grey--text text--lighten-1"
  }, [_vm._v("(단위: 만원)")]), _c('div', {
    staticClass: "v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable my-10px"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("공급유형")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("주거전용(타입)")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("공급호수")]), _c('th-primary', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("보증금 30%")]), _c('th-primary', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("보증금 35%")]), _c('th-primary', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("보증금 40%")])], 1), _c('tr', [_c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("보증금")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("임대료")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("보증금")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("임대료")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("보증금")]), _c('th-secondary', [_vm._v("임대료")])], 1)]), _c('tbody', [_c('tr', {
    staticClass: "table-head-divider"
  }, [_c('th-secondary', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("청년형"), _c('br'), _vm._v("(1인)")]), _c('td-primary', [_vm._v("17.60㎡ (A)")]), _c('td-primary', [_vm._v("15")]), _c('td-primary', [_vm._v("3,800")]), _c('td-primary', [_vm._v("39")]), _c('td-primary', [_vm._v("4,300")]), _c('td-primary', [_vm._v("37")]), _c('td-primary', [_vm._v("5,000")]), _c('td-primary', [_vm._v("33")])], 1), _c('tr', [_c('td-primary', [_vm._v("17.02㎡ (D)")]), _c('td-primary', [_vm._v("13")]), _c('td-primary', [_vm._v("3,600")]), _c('td-primary', [_vm._v("38")]), _c('td-primary', [_vm._v("4,200")]), _c('td-primary', [_vm._v("35")]), _c('td-primary', [_vm._v("4,800")]), _c('td-primary', [_vm._v("32")])], 1), _c('tr', {
    staticClass: "table-divider"
  }, [_c('td-primary', [_vm._v("17.54㎡ (E)")]), _c('td-primary', [_vm._v("3")]), _c('td-primary', [_vm._v("3,700")]), _c('td-primary', [_vm._v("39")]), _c('td-primary', [_vm._v("4,300")]), _c('td-primary', [_vm._v("36")]), _c('td-primary', [_vm._v("4,900")]), _c('td-primary', [_vm._v("33")])], 1), _c('tr', [_c('th-secondary', {
    attrs: {
      "rowspan": "5"
    }
  }, [_vm._v("신혼부부형"), _c('br'), _vm._v("(예비신혼포함)")]), _c('td-primary', [_vm._v("34.72㎡ (F)")]), _c('td-primary', [_vm._v("1")]), _c('td-primary', [_vm._v("5,800")]), _c('td-primary', [_vm._v("54")]), _c('td-primary', [_vm._v("6,700")]), _c('td-primary', [_vm._v("51")]), _c('td-primary', [_vm._v("7,700")]), _c('td-primary', [_vm._v("46")])], 1), _c('tr', [_c('td-primary', [_vm._v("35.84㎡ (B)")]), _c('td-primary', [_vm._v("9")]), _c('td-primary', [_vm._v("5,900")]), _c('td-primary', [_vm._v("55")]), _c('td-primary', [_vm._v("6,900")]), _c('td-primary', [_vm._v("51")]), _c('td-primary', [_vm._v("7,800")]), _c('td-primary', [_vm._v("48")])], 1), _c('tr', [_c('td-primary', [_vm._v("35.14㎡ (C)")]), _c('td-primary', [_vm._v("5")]), _c('td-primary', [_vm._v("5,900")]), _c('td-primary', [_vm._v("54")]), _c('td-primary', [_vm._v("6,800")]), _c('td-primary', [_vm._v("51")]), _c('td-primary', [_vm._v("7,700")]), _c('td-primary', [_vm._v("47")])], 1), _c('tr', [_c('td-primary', [_vm._v("36.39㎡ (G)")]), _c('td-primary', [_vm._v("-")])], 1), _c('tr', [_c('td-primary', [_vm._v("39.03㎡ (H)")]), _c('td-primary', [_vm._v("-")])], 1)])])])]), _c('txt-primary', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v("※ 임대보증금을 월 임대료로 전환하거나, 월 임대료를 임대보증금으로 전환하는 경우에는 임대 보증금비율은 "), _c('strong', [_vm._v("30%, 35%, 40%")]), _vm._v(" 단위로 전환이 가능하며 제시된 비율 외 임대 보증금의 변경은 사업주와 협의 가능합니다. 입주 전 선택한 임대 보증금 비율은 계약 갱신 전까지 변경 불가능합니다.")])], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--xs"
  }, [_c('tit-tertiary', [_vm._v("일반공급")])], 1), _c('div', {
    staticClass: "pb-18px pb-md-24px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-quarternary', [_vm._v("총 182세대")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip-group', [_c('chip-tertiary', {
    attrs: {
      "color": "quarternary"
    }
  }, [_vm._v("청년형 | 122세대")]), _c('chip-tertiary', {
    attrs: {
      "color": "tertiary"
    }
  }, [_vm._v("신혼부부형 | 60세대")])], 1)], 1)], 1)], 1), _c('txt-primary', {
    staticClass: "text-right grey--text text--lighten-1"
  }, [_vm._v("(단위: 만원)")]), _c('div', {
    staticClass: "v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable my-10px"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("공급유형")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("주거전용(타입)")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("공급호수")]), _c('th-primary', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("보증금 30%")]), _c('th-primary', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("보증금 35%")]), _c('th-primary', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("보증금 40%")])], 1), _c('tr', [_c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("보증금")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("임대료")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("보증금")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("임대료")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("보증금")]), _c('th-secondary', [_vm._v("임대료")])], 1)]), _c('tbody', [_c('tr', {
    staticClass: "table-head-divider"
  }, [_c('th-secondary', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("청년형"), _c('br'), _vm._v("(1인)")]), _c('td-primary', [_vm._v("17.60㎡ (A)")]), _c('td-primary', [_vm._v("60")]), _c('td-primary', [_vm._v("4,400")]), _c('td-primary', [_vm._v("47")]), _c('td-primary', [_vm._v("5,200")]), _c('td-primary', [_vm._v("43")]), _c('td-primary', [_vm._v("5,900")]), _c('td-primary', [_vm._v("40")])], 1), _c('tr', [_c('td-primary', [_vm._v("17.02㎡ (D)")]), _c('td-primary', [_vm._v("50")]), _c('td-primary', [_vm._v("4,300")]), _c('td-primary', [_vm._v("45")]), _c('td-primary', [_vm._v("5,000")]), _c('td-primary', [_vm._v("42")]), _c('td-primary', [_vm._v("5,700")]), _c('td-primary', [_vm._v("39")])], 1), _c('tr', {
    staticClass: "table-divider"
  }, [_c('td-primary', [_vm._v("17.54㎡ (E)")]), _c('td-primary', [_vm._v("12")]), _c('td-primary', [_vm._v("4,400")]), _c('td-primary', [_vm._v("46")]), _c('td-primary', [_vm._v("5,100")]), _c('td-primary', [_vm._v("43")]), _c('td-primary', [_vm._v("5,900")]), _c('td-primary', [_vm._v("40")])], 1), _c('tr', [_c('th-secondary', {
    attrs: {
      "rowspan": "5"
    }
  }, [_vm._v("신혼부부형"), _c('br'), _vm._v("(예비신혼포함)")]), _c('td-primary', [_vm._v("34.72㎡ (F)")]), _c('td-primary', [_vm._v("3")]), _c('td-primary', [_vm._v("6,800")]), _c('td-primary', [_vm._v("65")]), _c('td-primary', [_vm._v("8,000")]), _c('td-primary', [_vm._v("60")]), _c('td-primary', [_vm._v("9,100")]), _c('td-primary', [_vm._v("55")])], 1), _c('tr', [_c('td-primary', [_vm._v("35.84㎡ (B)")]), _c('td-primary', [_vm._v("35")]), _c('td-primary', [_vm._v("7,000")]), _c('td-primary', [_vm._v("66")]), _c('td-primary', [_vm._v("8,200")]), _c('td-primary', [_vm._v("61")]), _c('td-primary', [_vm._v("9,300")]), _c('td-primary', [_vm._v("57")])], 1), _c('tr', [_c('td-primary', [_vm._v("35.14㎡ (C)")]), _c('td-primary', [_vm._v("18")]), _c('td-primary', [_vm._v("6,900")]), _c('td-primary', [_vm._v("65")]), _c('td-primary', [_vm._v("8,100")]), _c('td-primary', [_vm._v("60")]), _c('td-primary', [_vm._v("9,200")]), _c('td-primary', [_vm._v("56")])], 1), _c('tr', [_c('td-primary', [_vm._v("36.39㎡ (G)")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("7,100")]), _c('td-primary', [_vm._v("67")]), _c('td-primary', [_vm._v("8,300")]), _c('td-primary', [_vm._v("62")]), _c('td-primary', [_vm._v("9,400")]), _c('td-primary', [_vm._v("57")])], 1), _c('tr', [_c('td-primary', [_vm._v("39.03㎡ (H)")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("7,300")]), _c('td-primary', [_vm._v("69")]), _c('td-primary', [_vm._v("8,500")]), _c('td-primary', [_vm._v("64")]), _c('td-primary', [_vm._v("9,800")]), _c('td-primary', [_vm._v("59")])], 1)])])])]), _c('txt-primary', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v("※ 임대보증금을 월 임대료로 전환하거나, 월 임대료를 임대보증금으로 전환하는 경우에는 임대 보증금비율은 "), _c('strong', [_vm._v("30%, 35%, 40%")]), _vm._v(" 단위로 전환이 가능하며 제시된 비율 외 임대 보증금의 변경은 사업주와 협의 가능합니다. 입주 전 선택한 임대 보증금 비율은 계약 갱신 전까지 변경 불가능합니다.")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }