<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="제출서류" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>LUMINO 816 청약신청 제출서류</template>

            <v-row>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-01.jpg" class="document">
                        <div class="document__caption">
                            1. 서류 체크리스트 [일반공급]
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-02.jpg" class="document">
                        <div class="document__caption">
                            2. 서류 체크리스트 [특별공급]
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-03.jpg" class="document">
                        <div class="document__caption">
                            3. 공공지원민간임대주택 등 공급신청서
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-04.jpg" class="document">
                        <div class="document__caption">
                            4. 서약서
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-05.jpg" class="document">
                        <div class="document__caption">
                            5. 월평균 소득현황
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-06.jpg" class="document">
                        <div class="document__caption">
                            6. 무소득 확인서
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-07.jpg" class="document">
                        <div class="document__caption">
                            7. 개인정보 활용 동의서
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-08.jpg" class="document">
                        <div class="document__caption">
                            8. 자산 보유 사실 확인서
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-09.jpg" class="document">
                        <div class="document__caption">
                            9. 금융정보등(금융,신용,보험정보)제공 동의서
                        </div>
                    </v-img>
                </v-col>
                <v-col cols="6" md="4">
                    <v-img src="/images/sub/subscription/document/document-10.jpg" class="document">
                        <div class="document__caption">
                            10. 비사업자 확인각서
                        </div>
                    </v-img>
                </v-col>
            </v-row>
            <div class="btn-wrap btn-wrap--lg">
                <btn-primary x-large href="/assets/document/documents.zip" target="_blank">제출서류 다운로드<v-img class="ml-16px ml-lg-24px" src="/images/icon/icon-download-white.png" /></btn-primary>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import btnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        btnPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.document {
    position: relative;
    &__caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 56px;
        background: rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--txt-font-size-lg);
        color: #fff;
        transition: all 0.5s ease;
        padding: 12px;
    }
    &:hover .document__caption {
        min-height: 100%;
    }
}
</style>
