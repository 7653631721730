<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="청약신청" bg="/images/form/application/visual.jpg">
                <template #title>청약신청</template>
            </sub-head-secondary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>루미노816 청약신청 조회</template>
            <v-divider style="border-width: 2px 0 0" class="grey darken-4" />
            <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                <v-row no-gutters align="center" class="txt txt--sm">
                    <v-col cols="12" md="auto">
                        <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">접수일</div>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ application?.createdAt?.toDateTime() }}</div>
                    </v-col>
                </v-row>
                <v-divider />
            </v-sheet>
            <v-row no-gutters>
                <v-col cols="6" class="border-r">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">이름</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ application?.name }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
                <v-col cols="6">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">신청자격</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ QUALIFICATION_TYPE[application?.qualification_type]?.text }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
                <v-col cols="6" class="border-r">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">생년월일</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ birthFormat(application?.birth) }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
                <v-col cols="6">
                    <v-sheet class="d-flex flex-column" style="flex: 1 0 auto">
                        <v-row no-gutters align="center" class="txt txt--sm">
                            <v-col cols="12" md="auto">
                                <div class="w-md-140px txt--dark font-weight-bold pt-16px pb-4px px-10px px-md-20px py-md-16px break-keep">공급유형</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">{{ SUPPLY_CATEGORY[application?.supply_category]?.text }}</div>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </v-sheet>
                </v-col>
            </v-row>

            <div class="btn-wrap">
                <v-row class="row--xs" justify="center">
                    <v-col cols="auto">
                        <v-btn x-large tile class="v-btn--width-fixed white--text" color="black" @click="go">신청서 수정</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import api from "@/api";
import { SUPPLY_CATEGORY, initApplication, QUALIFICATION_TYPE } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadSecondary,
        PageSectionPrimary,

        TitWrap,
        Tit,
        Txt,

        SheetPrimary,
    },
    props: {
        _application: {type: String, default: ""}
    },
    data: (()=> ({
        QUALIFICATION_TYPE,
        SUPPLY_CATEGORY,
        application: initApplication()
    })),
    mounted (){
        if(!this.$store.state.ci) {
            alert("본인인증이 필요합니다.");
            this.$router.go(-1);
        } else {
            this.init();
        }
    },
    methods: {
        init() {
            if(!this._application) throw new Error("잘못된 요청입니다.");
            this.search();
        },
        async search() {
            try { 
                let { application } = await api.v1.application.get({ _id: this._application });
                this.application = initApplication(application);
            } catch(error) {
                console.error(error);
            }
        },
        birthFormat(birth){
            return (birth?.length === 6 ? (birth[0] <= '2' ? '20' : '19') + birth : birth?.slice(0, 4)) + '. ' + birth?.slice(-4, -2) + '. ' + birth?.slice(-2) + '.';
        },
        go() {
            this.$router.push(`/form/application/${this._application}`);
        }
    },
};
</script>
