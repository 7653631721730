var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "청약일정",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("청약일정")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "md": "4",
      "lg": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/subscription/schedule/schedule.jpg"
    }
  })], 1), _c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "secondary"
    }
  }, [_c('tit-tertiary', [_vm._v("루미노 816 청약 일정 안내")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("입주자 모집공고")]), _vm._v("2024.08.01 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("청약신청 및 서류제출")]), _vm._v("2024.08.12~ 2024.08.14 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("당첨자 발표")]), _vm._v("2024.08.16 ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card__last",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("계약 체결")]), _vm._v("2024.08.26~2024.08.30 ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }