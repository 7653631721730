<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="임대조건" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>임대조건</template>

            <div class="page-section__content">
                <u-tit-wrap-default class="tit-wrap--xs">
                    <tit-tertiary>특별공급</tit-tertiary>
                </u-tit-wrap-default>

                <div class="pb-18px pb-md-24px">
                    <v-row class="row--sm" align="center">
                        <v-col cols="auto">
                            <txt-quarternary>총 46세대</txt-quarternary>
                        </v-col>
                        <v-col cols="auto">
                            <v-chip-group>
                                <chip-tertiary color="quarternary">청년형 | 31세대</chip-tertiary>
                                <!-- <chip-tertiary color="secondary">쉐어형 | 1세대</chip-tertiary> -->
                                <chip-tertiary color="tertiary">신혼부부형 | 15세대</chip-tertiary>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                </div>

                <txt-primary class="text-right grey--text text--lighten-1">(단위: 만원)</txt-primary>
                <div class="v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable my-10px">
                    <div class="v-data-table__wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th-primary rowspan="2">공급유형</th-primary>
                                    <th-primary rowspan="2">주거전용(타입)</th-primary>
                                    <th-primary rowspan="2">공급호수</th-primary>
                                    <th-primary colspan="2">보증금 30%</th-primary>
                                    <th-primary colspan="2">보증금 35%</th-primary>
                                    <th-primary colspan="2">보증금 40%</th-primary>
                                </tr>
                                <tr>
                                    <th-secondary class="border-r-white">보증금</th-secondary>
                                    <th-secondary class="border-r-white">임대료</th-secondary>
                                    <th-secondary class="border-r-white">보증금</th-secondary>
                                    <th-secondary class="border-r-white">임대료</th-secondary>
                                    <th-secondary class="border-r-white">보증금</th-secondary>
                                    <th-secondary>임대료</th-secondary>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-head-divider">
                                    <th-secondary rowspan="3">청년형<br />(1인)</th-secondary>
                                    <td-primary>17.60㎡ (A)</td-primary>
                                    <td-primary>15</td-primary>
                                    <td-primary>3,800</td-primary>
                                    <td-primary>39</td-primary>
                                    <td-primary>4,300</td-primary>
                                    <td-primary>37</td-primary>
                                    <td-primary>5,000</td-primary>
                                    <td-primary>33</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>17.02㎡ (D)</td-primary>
                                    <td-primary>13</td-primary>
                                    <td-primary>3,600</td-primary>
                                    <td-primary>38</td-primary>
                                    <td-primary>4,200</td-primary>
                                    <td-primary>35</td-primary>
                                    <td-primary>4,800</td-primary>
                                    <td-primary>32</td-primary>
                                </tr>
                                <tr class="table-divider">
                                    <td-primary>17.54㎡ (E)</td-primary>
                                    <td-primary>3</td-primary>
                                    <td-primary>3,700</td-primary>
                                    <td-primary>39</td-primary>
                                    <td-primary>4,300</td-primary>
                                    <td-primary>36</td-primary>
                                    <td-primary>4,900</td-primary>
                                    <td-primary>33</td-primary>
                                </tr>
                                <tr>
                                    <th-secondary rowspan="5">신혼부부형<br />(예비신혼포함)</th-secondary>
                                    <td-primary>34.72㎡ (F)</td-primary>
                                    <td-primary>1</td-primary>
                                    <td-primary>5,800</td-primary>
                                    <td-primary>54</td-primary>
                                    <td-primary>6,700</td-primary>
                                    <td-primary>51</td-primary>
                                    <td-primary>7,700</td-primary>
                                    <td-primary>46</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>35.84㎡ (B)</td-primary>
                                    <td-primary>9</td-primary>
                                    <td-primary>5,900</td-primary>
                                    <td-primary>55</td-primary>
                                    <td-primary>6,900</td-primary>
                                    <td-primary>51</td-primary>
                                    <td-primary>7,800</td-primary>
                                    <td-primary>48</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>35.14㎡ (C)</td-primary>
                                    <td-primary>5</td-primary>
                                    <td-primary>5,900</td-primary>
                                    <td-primary>54</td-primary>
                                    <td-primary>6,800</td-primary>
                                    <td-primary>51</td-primary>
                                    <td-primary>7,700</td-primary>
                                    <td-primary>47</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>36.39㎡ (G)</td-primary>
                                    <td-primary>-</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>39.03㎡ (H)</td-primary>
                                    <td-primary>-</td-primary>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <txt-primary class="grey--text text--lighten-1">※ 임대보증금을 월 임대료로 전환하거나, 월 임대료를 임대보증금으로 전환하는 경우에는 임대 보증금비율은 <strong>30%, 35%, 40%</strong> 단위로 전환이 가능하며 제시된 비율 외 임대 보증금의 변경은 사업주와 협의 가능합니다. 입주 전 선택한 임대 보증금 비율은 계약 갱신 전까지 변경 불가능합니다.</txt-primary>
            </div>

            <div class="page-section__content">
                <u-tit-wrap-default class="tit-wrap--xs">
                    <tit-tertiary>일반공급</tit-tertiary>
                </u-tit-wrap-default>

                <div class="pb-18px pb-md-24px">
                    <v-row class="row--sm" align="center">
                        <v-col cols="auto">
                            <txt-quarternary>총 182세대</txt-quarternary>
                        </v-col>
                        <v-col cols="auto">
                            <v-chip-group>
                                <chip-tertiary color="quarternary">청년형 | 122세대</chip-tertiary>
                                <!-- <chip-tertiary color="secondary">쉐어형 | 3세대</chip-tertiary> -->
                                <chip-tertiary color="tertiary">신혼부부형 | 60세대</chip-tertiary>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                </div>

                <txt-primary class="text-right grey--text text--lighten-1">(단위: 만원)</txt-primary>
                <div class="v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable my-10px">
                    <div class="v-data-table__wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th-primary rowspan="2">공급유형</th-primary>
                                    <th-primary rowspan="2">주거전용(타입)</th-primary>
                                    <th-primary rowspan="2">공급호수</th-primary>
                                    <th-primary colspan="2">보증금 30%</th-primary>
                                    <th-primary colspan="2">보증금 35%</th-primary>
                                    <th-primary colspan="2">보증금 40%</th-primary>
                                </tr>
                                <tr>
                                    <th-secondary class="border-r-white">보증금</th-secondary>
                                    <th-secondary class="border-r-white">임대료</th-secondary>
                                    <th-secondary class="border-r-white">보증금</th-secondary>
                                    <th-secondary class="border-r-white">임대료</th-secondary>
                                    <th-secondary class="border-r-white">보증금</th-secondary>
                                    <th-secondary>임대료</th-secondary>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-head-divider">
                                    <th-secondary rowspan="3">청년형<br />(1인)</th-secondary>
                                    <td-primary>17.60㎡ (A)</td-primary>
                                    <td-primary>60</td-primary>
                                    <td-primary>4,400</td-primary>
                                    <td-primary>47</td-primary>
                                    <td-primary>5,200</td-primary>
                                    <td-primary>43</td-primary>
                                    <td-primary>5,900</td-primary>
                                    <td-primary>40</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>17.02㎡ (D)</td-primary>
                                    <td-primary>50</td-primary>
                                    <td-primary>4,300</td-primary>
                                    <td-primary>45</td-primary>
                                    <td-primary>5,000</td-primary>
                                    <td-primary>42</td-primary>
                                    <td-primary>5,700</td-primary>
                                    <td-primary>39</td-primary>
                                </tr>
                                <tr class="table-divider">
                                    <td-primary>17.54㎡ (E)</td-primary>
                                    <td-primary>12</td-primary>
                                    <td-primary>4,400</td-primary>
                                    <td-primary>46</td-primary>
                                    <td-primary>5,100</td-primary>
                                    <td-primary>43</td-primary>
                                    <td-primary>5,900</td-primary>
                                    <td-primary>40</td-primary>
                                </tr>
                                <tr>
                                    <th-secondary rowspan="5">신혼부부형<br />(예비신혼포함)</th-secondary>
                                    <td-primary>34.72㎡ (F)</td-primary>
                                    <td-primary>3</td-primary>
                                    <td-primary>6,800</td-primary>
                                    <td-primary>65</td-primary>
                                    <td-primary>8,000</td-primary>
                                    <td-primary>60</td-primary>
                                    <td-primary>9,100</td-primary>
                                    <td-primary>55</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>35.84㎡ (B)</td-primary>
                                    <td-primary>35</td-primary>
                                    <td-primary>7,000</td-primary>
                                    <td-primary>66</td-primary>
                                    <td-primary>8,200</td-primary>
                                    <td-primary>61</td-primary>
                                    <td-primary>9,300</td-primary>
                                    <td-primary>57</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>35.14㎡ (C)</td-primary>
                                    <td-primary>18</td-primary>
                                    <td-primary>6,900</td-primary>
                                    <td-primary>65</td-primary>
                                    <td-primary>8,100</td-primary>
                                    <td-primary>60</td-primary>
                                    <td-primary>9,200</td-primary>
                                    <td-primary>56</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>36.39㎡ (G)</td-primary>
                                    <td-primary>2</td-primary>
                                    <td-primary>7,100</td-primary>
                                    <td-primary>67</td-primary>
                                    <td-primary>8,300</td-primary>
                                    <td-primary>62</td-primary>
                                    <td-primary>9,400</td-primary>
                                    <td-primary>57</td-primary>
                                </tr>
                                <tr>
                                    <td-primary>39.03㎡ (H)</td-primary>
                                    <td-primary>2</td-primary>
                                    <td-primary>7,300</td-primary>
                                    <td-primary>69</td-primary>
                                    <td-primary>8,500</td-primary>
                                    <td-primary>64</td-primary>
                                    <td-primary>9,800</td-primary>
                                    <td-primary>59</td-primary>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <txt-primary class="grey--text text--lighten-1">※ 임대보증금을 월 임대료로 전환하거나, 월 임대료를 임대보증금으로 전환하는 경우에는 임대 보증금비율은 <strong>30%, 35%, 40%</strong> 단위로 전환이 가능하며 제시된 비율 외 임대 보증금의 변경은 사업주와 협의 가능합니다. 입주 전 선택한 임대 보증금 비율은 계약 갱신 전까지 변경 불가능합니다.</txt-primary>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import ChipTertiary from "@/components/publish/parents/chips/chip-tertiary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
        ChipTertiary,
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
