<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-secondary itemTitle="청약신청" bg="/images/form/application/visual.jpg">
                <template #title>청약신청</template>
            </sub-head-secondary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <v-row align="center">
                <v-col cols="12" lg="6" order-lg="2">
                    <v-card tile class="pa-24px px-md-40px py-md-84px">
                        <tit-wrap class="tit-wrap--sm">
                            <tit-wrap class="tit-wrap--xs">
                                <tit class="font-secondary secondary--text">Lumino816</tit>
                            </tit-wrap>
                            <tit>루미노816 청약신청 접수/조회</tit>
                        </tit-wrap>

                        <tit-wrap class="tit-wrap--sm">
                            <v-row class="row--xs">
                                <v-col cols="auto">
                                    <application-dialog>
                                        <template #activator="{ on, attrs }">
                                            <!-- <v-btn tile x-large color="black" class="white--text v-btn--width-fixed" :href="surveyURL" target="_blank"> -->
                                            <v-btn v-bind="attrs" v-on="on" tile x-large color="black" class="white--text v-btn--width-fixed">
                                                <span class="font-size-14 font-size-lg-16">청약신청/조회</span>
                                            </v-btn>
                                        </template>
                                    </application-dialog>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn tile x-large color="secondary" class="v-btn--width-fixed" to="/subscription/recruitment">
                                        <span class="font-size-14 font-size-lg-16">모집공고 다운로드</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </tit-wrap>
                        <sheet-primary>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="row--xxs">
                                        <v-col cols="12">
                                            <txt class="font-weight-bold txt--dark">주택위치</txt>
                                        </v-col>
                                        <v-col>
                                            <txt class="font-weight-bold">
                                                서울특별시 은평구 불광동 302-13번지 일원 <br />
                                                (3·6호선 연신내역 3번 출구)
                                            </txt>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row class="row--xxs">
                                        <v-col cols="12">
                                            <txt class="font-weight-bold txt--dark">공급호수</txt>
                                        </v-col>
                                        <v-col>
                                            <txt class="font-weight-bold">
                                                총 299세대 중 민간임대 228세대 <br />
                                                (특별공급 46세대, 일반공급 182세대)
                                            </txt>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </sheet-primary>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-img max-width="588" src="/images/sub/application/application.png" class="mx-auto" />
                </v-col>
            </v-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import { APPLICATION_TYPE } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import FormInput from "@/components/client/form/form-input.vue";

import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";
import api from "@/api";

import ApplicationDialog from "@/components/client/application/application-dialog.vue"

export default {
    components: {
        AppPrimary,
        SubHeadSecondary,
        PageSectionPrimary,
        FormInput,

        TitWrap,
        Tit,
        Txt,

        SheetPrimary,

        ApplicationDialog,
    },
    data() {
        return {
            APPLICATION_TYPE,
            surveyURL: "/",
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                const { surveyURL } = await api.v1.setting.getSuveryURL();
                this.surveyURL = surveyURL;
            } catch (error) {
                console.error(errro);
            }
        },
    },
};
</script>
