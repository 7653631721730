var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "588"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-block px-md-40px py-md-40px"
  }, [_c('txt', {
    staticClass: "font-weight-bold secondary--text text-center mb-12px mb-md-16px"
  }, [_vm._v("Lumino816")]), _c('tit', {
    staticClass: "text-center"
  }, [_vm._v("루미노816 청약신청 접수/조회")])], 1), _c('v-card-text', {
    staticClass: "px-md-40px pb-md-40px"
  }, [_c('v-sheet', {
    attrs: {
      "color": "grey lighten-5 d-flex justify-center align-center pa-16px pa-md-24px"
    }
  }, [_c('txt', [_vm._v("접수이력이 있다면, 조회 페이지로 이동합니다.")])], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center px-md-40px pb-md-40px"
  }, [_c('v-btn', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.certify
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-lg-16"
  }, [_vm._v("본인인증")])]), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.verify
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }