<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="임차인 자격요건" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>임차인 자격요건</template>

            <!-- S: 특별공급대상자 -->
            <div class="page-section__content">
                <u-tit-wrap-default class="tit-wrap--xs">
                    <tit-tertiary>특별공급대상자</tit-tertiary>
                </u-tit-wrap-default>
                <txt-quarternary>※입주자모집공고일(2022.2.09) 기준 해당 공급의 모든 요건에 해당하는 신청인(외국인 제외)</txt-quarternary>

                <div class="chip-wrap">
                    <chip-primary class="w-100">특별공급 신청자격</chip-primary>
                </div>
                <sheet-secondary>
                    <div class="list-item">
                        <v-row class="row--xs">
                            <v-col cols="12"><txt-quarternary class="dot">만 19세 이상 만 39세 이하인 자(1983년 02월 09일부터 2003년 02월 09일 사이 출생자)</txt-quarternary></v-col>
                            <v-col cols="12"><txt-quarternary class="dot">미혼</txt-quarternary></v-col>
                            <v-col cols="12"><txt-quarternary class="dot">무주택자</txt-quarternary></v-col>
                        </v-row>
                    </div>

                    <div class="list-item">
                        <v-row class="row--xs">
                            <v-col cols="12"><txt-quarternary>다음 각 항목의 구분에 따른 소득기준을 충족함</txt-quarternary></v-col>
                            <v-col cols="12">
                                <sheet-primary>
                                    <v-row class="row--xs">
                                        <v-col cols="12"><txt-quarternary class="dot">가. 주택공급 신청자가 소득이 있는 경우 : 해당 세대의 월평균소득이 전년도 도시 근로자 가구원수별 가구당 월평균 소득의 120% 이하임.</txt-quarternary></v-col>
                                        <v-col cols="12"><txt-quarternary class="dot">나. 주택공급 신청자가 소득이 없는 경우 : 부모의 월평균소득 합계가 전년도 도시 근로자 가구원수별 가구당 월평균 소득의 120% 이하임.</txt-quarternary></v-col>
                                    </v-row>
                                </sheet-primary>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="list-item">
                        <v-row class="row--xs">
                            <v-col cols="12"><txt-quarternary>(*도시 근로자 가구원수별 가구당 월평균 소득의 경우 아래 표 참고)</txt-quarternary></v-col>
                            <v-col cols="12">
                                <sheet-primary>
                                    <v-row class="row--xs">
                                        <v-col cols="12"><txt-quarternary class="dot">본인의 총 자산 가액 합산 기준 28,800만원이하.</txt-quarternary></v-col>
                                        <v-col cols="12"><txt-quarternary class="dot">자동차 무소유 및 미 운행자.</txt-quarternary></v-col>
                                        <v-col cols="12"><txt-quarternary class="dot">(단, 자동차가액 3,557만원 이하의 장애인 본인 사용, 생업용차량, 유자녀용, 125CC이하의 생업용 이륜차 대상 제외)</txt-quarternary></v-col>
                                    </v-row>
                                </sheet-primary>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="list-item">
                        <v-row class="row--xs">
                            <v-col cols="12">
                                <div class="v-data-table v-data-table--border v-data-table--line">
                                    <div class="v-data-table__wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th-primary>구분</th-primary>
                                                    <th-primary>1인 가구</th-primary>
                                                    <th-primary>2인 가구</th-primary>
                                                    <th-primary>3인 가구</th-primary>
                                                    <th-primary>4인 가구</th-primary>
                                                    <th-primary>5인 가구</th-primary>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th-secondary>월평균 소득(100%)</th-secondary>
                                                    <td-primary>3,212,113</td-primary>
                                                    <td-primary>4,844,370</td-primary>
                                                    <td-primary>6,418,566</td-primary>
                                                    <td-primary>7,200,809</td-primary>
                                                    <td-primary>7,326,072</td-primary>
                                                </tr>
                                                <tr>
                                                    <th-secondary>월평균 소득(110%)</th-secondary>
                                                    <td-primary>3,533,324</td-primary>
                                                    <td-primary>5,328,807</td-primary>
                                                    <td-primary>7,060,422</td-primary>
                                                    <td-primary>7,920,890</td-primary>
                                                    <td-primary>8,058,679</td-primary>
                                                </tr>
                                                <tr>
                                                    <th-secondary>월평균 소득(120%)</th-secondary>
                                                    <td-primary>2011호</td-primary>
                                                    <td-primary>2012호</td-primary>
                                                    <td-primary>2013호</td-primary>
                                                    <td-primary>2014호</td-primary>
                                                    <td-primary>2015호</td-primary>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <txt-primary class="grey--text text--lighten-1">* 6인 이상가구는 통계청이 발표하는 5인가구소득에 1인당평균금액*(384,376원)합산하여 산정.</txt-primary>
                            </v-col>
                        </v-row>
                    </div>
                </sheet-secondary>
            </div>
            <!-- E: 특별공급대상자 -->

            <!-- S: 일반공급대상자 -->
            <div class="page-section__content">
                <u-tit-wrap-default class="tit-wrap--xs">
                    <v-row class="row--sm">
                        <v-col cols="auto"><tit-tertiary>일반공급대상자</tit-tertiary></v-col>
                        <v-col cols="auto">
                            <v-chip-group>
                                <chip-secondary color="primary">1인가구(청년, 대학생)</chip-secondary>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                </u-tit-wrap-default>

                <txt-quarternary>※입주자모집공고일(2022.2.09) 기준 해당 공급의 모든 요건에 해당하는 신청인(외국인 제외)</txt-quarternary>

                <div class="chip-wrap">
                    <chip-primary class="w-100">일반공급 신청자격</chip-primary>
                </div>

                <sheet-secondary>
                    <v-row class="row--xs">
                        <v-col cols="12"><txt-quarternary class="dot">1인 가구(청년, 대학생)</txt-quarternary></v-col>
                        <v-col cols="12"><txt-quarternary class="dot">만 19세 이상 만 39세 이하인 자(1983년 02월 09일부터 2003년 02월 09일 사이 출생자)</txt-quarternary></v-col>
                        <v-col cols="12"><txt-quarternary class="dot">미혼.</txt-quarternary></v-col>
                        <v-col cols="12"><txt-quarternary class="dot">무주택자.</txt-quarternary></v-col>
                        <v-col cols="12"><txt-quarternary class="dot">소득 및 자산, 지역요건 없음.</txt-quarternary></v-col>
                        <v-col cols="12"><txt-quarternary class="dot">자동차 무소유 및 미 운행자.</txt-quarternary></v-col>
                        <v-col cols="12"><txt-quarternary class="dot">(단, 자동차가액 3,557만원 이하의 장애인 본인 사용, 생업용차량, 유자녀용, 125CC이하의 생업용 이륜차 대상 제외)</txt-quarternary></v-col>
                        <v-col cols="12"><txt-primary class="grey--text text--lighten-1">* 예외차량은 [모집공고 다운로드]에서 "차량등록 기준 및 기타 제출서류" 참고.</txt-primary></v-col>
                    </v-row>
                </sheet-secondary>
            </div>
            <!-- E: 일반공급대상자 -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";
import SheetSecondary from "@/components/publish/parents/sheets/sheet-secondary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import ChipPrimary from "@/components/publish/parents/chips/chip-primary.vue";
import ChipSecondary from "@/components/publish/parents/chips/chip-secondary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        SheetPrimary,
        SheetSecondary,
        UTitWrapDefault,
        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
        ChipPrimary,
        ChipSecondary,
        TablePrimary,
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {
            headers: [
                { text: "구분", value: "Title1" },
                { text: "Title2", value: "Title2" },
                { text: "Title3", value: "Title3" },
            ],
            items: [
                { Title1: "Text1-1", Title2: "Text1-2", Title3: "Text1-3" },
                { Title1: "Text2-1", Title2: "Text2-2", Title3: "Text2-3" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.chip-wrap {
    margin-top: 30px;
    margin-bottom: 14px;
    width: 160px;
}
.list-item + .list-item {
    margin-top: 28px;
}
@media (min-width: 768px) {
    .chip-wrap {
        margin-top: 40px;
        margin-bottom: 16px;
        width: 206px;
    }
    .list-item + .list-item {
        margin-top: 36px;
    }
}
</style>
