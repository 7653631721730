var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "임차인 자격요건",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("임차인 자격요건")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--xs"
  }, [_c('tit-tertiary', [_vm._v("특별공급대상자")])], 1), _c('txt-quarternary', [_vm._v("※입주자모집공고일(2022.2.09) 기준 해당 공급의 모든 요건에 해당하는 신청인(외국인 제외)")]), _c('div', {
    staticClass: "chip-wrap"
  }, [_c('chip-primary', {
    staticClass: "w-100"
  }, [_vm._v("특별공급 신청자격")])], 1), _c('sheet-secondary', [_c('div', {
    staticClass: "list-item"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("만 19세 이상 만 39세 이하인 자(1983년 02월 09일부터 2003년 02월 09일 사이 출생자)")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("미혼")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("무주택자")])], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', [_vm._v("다음 각 항목의 구분에 따른 소득기준을 충족함")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('sheet-primary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("가. 주택공급 신청자가 소득이 있는 경우 : 해당 세대의 월평균소득이 전년도 도시 근로자 가구원수별 가구당 월평균 소득의 120% 이하임.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("나. 주택공급 신청자가 소득이 없는 경우 : 부모의 월평균소득 합계가 전년도 도시 근로자 가구원수별 가구당 월평균 소득의 120% 이하임.")])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', [_vm._v("(*도시 근로자 가구원수별 가구당 월평균 소득의 경우 아래 표 참고)")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('sheet-primary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("본인의 총 자산 가액 합산 기준 28,800만원이하.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("자동차 무소유 및 미 운행자.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("(단, 자동차가액 3,557만원 이하의 장애인 본인 사용, 생업용차량, 유자녀용, 125CC이하의 생업용 이륜차 대상 제외)")])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "v-data-table v-data-table--border v-data-table--line"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', [_vm._v("구분")]), _c('th-primary', [_vm._v("1인 가구")]), _c('th-primary', [_vm._v("2인 가구")]), _c('th-primary', [_vm._v("3인 가구")]), _c('th-primary', [_vm._v("4인 가구")]), _c('th-primary', [_vm._v("5인 가구")])], 1)]), _c('tbody', [_c('tr', [_c('th-secondary', [_vm._v("월평균 소득(100%)")]), _c('td-primary', [_vm._v("3,212,113")]), _c('td-primary', [_vm._v("4,844,370")]), _c('td-primary', [_vm._v("6,418,566")]), _c('td-primary', [_vm._v("7,200,809")]), _c('td-primary', [_vm._v("7,326,072")])], 1), _c('tr', [_c('th-secondary', [_vm._v("월평균 소득(110%)")]), _c('td-primary', [_vm._v("3,533,324")]), _c('td-primary', [_vm._v("5,328,807")]), _c('td-primary', [_vm._v("7,060,422")]), _c('td-primary', [_vm._v("7,920,890")]), _c('td-primary', [_vm._v("8,058,679")])], 1), _c('tr', [_c('th-secondary', [_vm._v("월평균 소득(120%)")]), _c('td-primary', [_vm._v("2011호")]), _c('td-primary', [_vm._v("2012호")]), _c('td-primary', [_vm._v("2013호")]), _c('td-primary', [_vm._v("2014호")]), _c('td-primary', [_vm._v("2015호")])], 1)])])])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-primary', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v("* 6인 이상가구는 통계청이 발표하는 5인가구소득에 1인당평균금액*(384,376원)합산하여 산정.")])], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--xs"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tit-tertiary', [_vm._v("일반공급대상자")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip-group', [_c('chip-secondary', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("1인가구(청년, 대학생)")])], 1)], 1)], 1)], 1), _c('txt-quarternary', [_vm._v("※입주자모집공고일(2022.2.09) 기준 해당 공급의 모든 요건에 해당하는 신청인(외국인 제외)")]), _c('div', {
    staticClass: "chip-wrap"
  }, [_c('chip-primary', {
    staticClass: "w-100"
  }, [_vm._v("일반공급 신청자격")])], 1), _c('sheet-secondary', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("1인 가구(청년, 대학생)")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("만 19세 이상 만 39세 이하인 자(1983년 02월 09일부터 2003년 02월 09일 사이 출생자)")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("미혼.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("무주택자.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("소득 및 자산, 지역요건 없음.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("자동차 무소유 및 미 운행자.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-quarternary', {
    staticClass: "dot"
  }, [_vm._v("(단, 자동차가액 3,557만원 이하의 장애인 본인 사용, 생업용차량, 유자녀용, 125CC이하의 생업용 이륜차 대상 제외)")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-primary', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v("* 예외차량은 [모집공고 다운로드]에서 \"차량등록 기준 및 기타 제출서류\" 참고.")])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }